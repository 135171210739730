.DTK > p > img {
  /* DTK images: Diagrams, Tables and Maps. Applied on QuestionViewDtkOrg.js */
  width: 100%;
}

.QuestionStatement > p > img {
  /* All other images on question except DTK. Applied on QuestionBody.js */
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;
  max-width: 400px;
}

.QuestionStatement {
  font-size: 1.3rem;
  font-weight: 400;
}

.Explaination > p > img {
  /* All images within "Explaination field" QuestionBody.js*/
  width: 100%;
  max-width: 400px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.noto-sans {
  font-family: "Noto Sans", sans-serif;
}

.css-10wpov9-MuiTypography-root {
  font-size: 1rem !important;
}

@media (max-width: 600px) {
  .css-10wpov9-MuiTypography-root {
    font-size: 16px !important;
  }
}

.css-1qf41gp-MuiTypography-root {
  font-size: 1rem !important;
}

@media (max-width: 600px) {
  .css-1qf41gp-MuiTypography-root {
    font-size: 16px !important;
  }
}

.css-ccze1n-MuiTypography-root {
  font-size: 16px !important;
}

@media (max-width: 600px) {
  .css-ccze1n-MuiTypography-root {
    font-size: 16px !important;
  }
}

.css-ag7rrr-MuiTypography-root {
  font-size: 16px !important;
}

@media (max-width: 600px) {
  .css-ag7rrr-MuiTypography-root {
    font-size: 16px !important;
  }
}

.MuiTypography-root.MuiTypography-body1.css-ahj2mt-MuiTypography-root > p {
  font-size: 16px !important;
}

@media (max-width: 600px) {
  .MuiTypography-root.MuiTypography-body1.css-ahj2mt-MuiTypography-root > p {
    font-size: 16px !important;
    font-weight: 500;
  }
}

.css-19w1iz8 {
  font-size: 16px !important;
}

@media (max-width: 600px) {
  .css-19w1iz8 {
    font-size: 16px !important;
  }
}
