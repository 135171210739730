@import url(https://fonts.googleapis.com/css2?family=Figtree:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abel:wght@100;200;300;400;500;600;700;800;900&display=swap);

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif !important;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.MuiTypography-root,
.MuiTypography-body2 {
  font-family: inherit !important;
}

.MuiTabs-root .MuiTab-root {
  font-family: inherit !important;
}

.css-1ex1afd-MuiTableCell-root {
  font-family: inherit !important;
}

.MuiButton-root {
  font-family: inherit !important;
}

label > img {
  width: 25px;
}

p {
  margin: 0;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

blockquote {
  margin: 0 0 1rem;
  color: rgba(0, 0, 0, 0.5);
  margin: 1em 0;
  padding-left: 1.5em;
  border-left: 5px solid rgba(0, 0, 0, 0.1);
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

input::placeholder {
  color: "#E1E1E1";
}

.MuiTypography-root.MuiTypography-subtitle1 > * {
  display: block;
  margin-bottom: 1.2em;
}

.MuiTypography-root.MuiTypography-body1.css-ahj2mt-MuiTypography-root > p {
  padding-bottom: 1em;
}

.swal-modal .swal-text {
  text-align: center;
}

.optionImage p img {
  width: 50%;
}

.optionImage p {
  text-align: center;
  width: 100%;
}

.questionImage p {
  text-align: center;
  width: 100%;
}

.questionImage p img {
  width: 100%;
}

.input-style input::placeholder {
  color: #a6a6a6;
}

.input-style input:focus {
  border: 1px solid #1b51da !important;
  /* outline: tomato;
  border-width: 3px;
  border-color: blue; */
}

.blahstyle:focus {
  border: 1px solid #1b51da !important;
}
.passwordStrengthBar > div > div {
  height: 7.12px !important;
  border-radius: 2.97px;
}
.passwordStrengthBar > div > div:nth-child(even) {
  height: 7.12px !important;
  border-radius: 2.97px;
}

.filled-btn-1 button:hover {
  background-color: #517be7 !important;
}

.filled-btn-1 button:disabled {
  color: white;
}

.css-1fx9dgs {
  width: 190%;
}

.css-ahj2mt-MuiTypography-root {
  font-size: inherit !important ;
}

.css-1ex1afd-MuiTableCell-root {
  font-size: 12px !important;
}

.css-177gid-MuiTableCell-root {
  font-family: inherit !important;
  font-size: 12px !important;
}

.css-1h8bvdk {
  font-family: "Poppins", sans-serif;
}

.css-1ygcj2i-MuiTableCell-root {
  font-family: inherit !important;
}

.DTK > p > img {
  /* DTK images: Diagrams, Tables and Maps. Applied on QuestionViewDtkOrg.js */
  width: 100%;
}

.QuestionStatement > p > img {
  /* All other images on question except DTK. Applied on QuestionBody.js */
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;
  max-width: 400px;
}

.QuestionStatement {
  font-size: 1.3rem;
  font-weight: 400;
}

.Explaination > p > img {
  /* All images within "Explaination field" QuestionBody.js*/
  width: 100%;
  max-width: 400px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.noto-sans {
  font-family: "Noto Sans", sans-serif;
}

.css-10wpov9-MuiTypography-root {
  font-size: 1rem !important;
}

@media (max-width: 600px) {
  .css-10wpov9-MuiTypography-root {
    font-size: 16px !important;
  }
}

.css-1qf41gp-MuiTypography-root {
  font-size: 1rem !important;
}

@media (max-width: 600px) {
  .css-1qf41gp-MuiTypography-root {
    font-size: 16px !important;
  }
}

.css-ccze1n-MuiTypography-root {
  font-size: 16px !important;
}

@media (max-width: 600px) {
  .css-ccze1n-MuiTypography-root {
    font-size: 16px !important;
  }
}

.css-ag7rrr-MuiTypography-root {
  font-size: 16px !important;
}

@media (max-width: 600px) {
  .css-ag7rrr-MuiTypography-root {
    font-size: 16px !important;
  }
}

.MuiTypography-root.MuiTypography-body1.css-ahj2mt-MuiTypography-root > p {
  font-size: 16px !important;
}

@media (max-width: 600px) {
  .MuiTypography-root.MuiTypography-body1.css-ahj2mt-MuiTypography-root > p {
    font-size: 16px !important;
    font-weight: 500;
  }
}

.css-19w1iz8 {
  font-size: 16px !important;
}

@media (max-width: 600px) {
  .css-19w1iz8 {
    font-size: 16px !important;
  }
}

.page {
  position: relative;
  box-sizing: border-box;
}

.container {
  position: fixed;
  top: 30%;
  left: 73%;
}

.moveable {
  position: absolute;
  text-align: center;
}

.description {
  text-align: center;
}

.badges {
  padding: 10px;
  text-align: center;
}

.badges img {
  height: 20px;
}

.page.main {
  z-index: 1000;
}

.label {
  position: relative;
  top: 0;
  left: 0;
  padding: 5px;
  border-radius: 5px;
  background-color: transparent;
  font-weight: bold;
  font-size: 0px;
  display: none;
  transform: translate(-100%, -100%);
}

.draggable,
.resizable,
.scalable,
.rotatable,
.origin,
.warpable,
.pinchable {
  position: absolute;
  left: 0;
}

